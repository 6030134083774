.rounded-rectangle {
    position: relative;
    width: 100%;
    max-width: 640px; /* Optional: max-width to limit size on larger screens */
    margin: auto; /* Center on the page */
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    border-radius: 20px; /* Adjust this value for more or less rounding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
    background: #000; /* Optional: background color */
}

.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}


.video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
